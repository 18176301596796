import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"


const renderIt = () => {
  return <>
    <h4 className="display-medium font-300 text-primary mb-3">Termini di utilizzo</h4>    
    <h5>Definizioni</h5>
    <ul className="mt-2">
      <li>
        Il termine 'INMAGIK' o 'noi' si riferisce al proprietario del sito la cui sede legale è via Sant’Orsola 2, Bergamo, Italia.
        <br></br>
        Il numero di registrazione della nostra società presso il Registro imprese di Bergamo è BG-420856 (numero REA).
      </li>
      <li>
        Il termine 'voi' si riferisce agli utenti o ai visitatori del nostro sito.
      </li>
    </ul>

    <h5>Termini e condizioni di utilizzo</h5>
    <p>
      L'uso di questo sito web è soggetto ai seguenti termini e condizioni.
    </p>
      <ul className="mt-2">
        <li>
        Il contenuto di questo sito ha lo scopo di fornire informazioni di carattere generale ed è soggetto a modifiche senza preavviso.
        </li>
        <li>
          Né noi né terzi siamo tenuti a fornire alcuna garanzia circa l'esattezza, la tempestività, la performance, la completezza o l'idoneità delle informazioni e dei materiali trovati o offerti su questo sito per qualsiasi scopo particolare. L'utente riconosce che tali informazioni e materiali possono contenere imprecisioni o errori e si escludono espressamente la responsabilità per eventuali imprecisioni o errori nella misura massima consentita dalla legge.
        </li>
        <li>
          L'utilizzo di qualsiasi informazione o materiale presi da questo sito è interamente a proprio rischio, e quindi non saremo responsabili di eventuali conseguenze. Sarà vostra responsabilità assicurarvi che tutti i prodotti, servizi o informazioni disponibili attraverso questo sito soddisfino le vostre specifiche esigenze.
        </li>
        <li>
          INMAGIK SRL non si assume alcuna responsabilità nel caso in cui il sito web non sia disponibile a causa di problemi tecnici indipendenti dalla nostra volontà.
        </li>
        <li>
          Tutti i marchi riprodotti in questo sito, che non sono di proprietà di, o concessi in licenza per l'operatore, sono riconosciuti su questo sito web.
        </li>
        <li>
          L'uso non autorizzato di questo sito o qualsiasi contenuto riprodotto in esso può dar luogo a un risarcimento del danno e / o essere un reato penale.
        </li>
        <li>
          Questo sito può contenere link a siti esterni e risorse su internet. Questi collegamenti sono forniti per comodità e / o di fornire ulteriori informazioni. L'inclusione di qualsiasi link non implica alcuna raccomandazione o approvazione delle idee espresse all'interno di essi. Non abbiamo alcuna responsabilità per il contenuto dei siti web collegati al nostro sito tramite link(s).
        </li>
        <li>
          Non è consentito creare un link a questo sito da un altro sito web o da un documento senza un consenso scritto di INMAGIK.
        </li>
        <li>
          L'utilizzo di questo sito web e tutte le controversie derivanti da tale utilizzo è soggetto alle leggi vigenti in Italia.
        </li>
        <li>
          Il sito Inmagik può memorizzare cookie e / o informazioni "localStorage" circa l'esperienza di navigazione sul dispositivo solo per motivi tecnici (per consentire al sito web di funzionare correttamente) e per le analisi aggregate (il tuo IP è anonimo).
          Questo sito non fa uso di cookies per la profilazione degli utenti e per altri scopi commerciali.
        </li>
      </ul>
      <p>
        Before considering any partial or full reproduction or reuse of this website content, be sure to review ourPrima di attuare qualsiasi riproduzione parziale o totale o riutilizzo del contenuto di questo sito web, assicurati di aver letto la nostra pagina <Link to="/copyright">Copyright notice</Link>.
      </p>
  </>
}


const renderEn = () => {
  return <>
    <h4 className="display-medium font-300 text-primary mb-3">Terms and conditions of use</h4>    
    <h5>Definitions</h5>
    <ul className="mt-2">
      <li>
        The term 'INMAGIK' or 'us' or 'we' refers to the owner of the website whose registered office is via Sant’Orsola 2, Bergamo, Italy.
        <br></br>
        Our company registration number (numero REA) is BG-420856 Registro imprese di Bergamo.
      </li>
      <li>
      The term 'you' refers to the user or viewer of our website.
      </li>
    </ul>

    <h5>Terms of use</h5>
      <p>
      The use of this website is subject to the following terms.
      </p>
      <ul className="mt-2">
        <li>
        The content of this website is for your general information and use only. It is subject to change without any notice.
        </li>
        <li>
          Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
        </li>
        <li>
        Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.
        </li>
        <li>
        INMAGIK SRL takes no responsibility for, and will not be liable for, the website being unavailable due to technical issues beyond our control.
        </li>
        <li>
          All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.
        </li>
        <li>
          Unauthorised use of this website or any content reproduced in it may give rise to a claim for damages and/or be a criminal offence.
        </li>
        <li>
          This website may include links to external websites and resources on the internet. These links are provided for your convenience and/or to provide further information. The inclusion of any links does not imply a recommendation or endorsement of the ideas expressed within them. We have no responsibility for the content of the linked website(s).
        </li>
        <li>
          You may not create a link to this website from another website or document without INMAGIK prior written consent.
        </li>
        <li>
          Your use of this website and any dispute arising out of such use is subject to the laws of Italy.
        </li>
        <li>
          Inmagik website may store cookies and/or localstorage information about the navigation experience on your device only for technical reasons (for the website to work properly) and for aggregated analytics (your ip is anonymized).
          This website does not use cookies for user profilation and for other commercial purposes.
        </li>
      </ul>
      <p>
        Before considering any partial or full reproduction or reuse of this website content, be sure to review our <Link to="/copyright">Copyright notice</Link>.
      </p>
  </>
    
  
}



const Page = ({data, intl}) => {
  
  return (
  <Layout>
    <SEO title="Privacy policy - INMAGIK" />
    <div className="main-content container p-4 font-100">
      {intl.locale === 'it' ?  renderIt() : renderEn()}
    </div>
  </Layout>
)}

export default injectIntl(Page)
